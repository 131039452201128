.swiper {
  width: 100%;
  height: 100%;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem;
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 50%;
  font-weight: 400;
}

.pagination .page-num:hover {
  background-color: #36A9E1;
  color: white;
}

.pagination .active {
  background-color: #36A9E1;
  color: white;
}
