@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
      /* main colors */
      --color-text-base: 183, 205, 0;
      --color-text-muted: 255, 255, 255;
      --color-text-inverted: 35, 29, 79;
      --color-fill: 183, 205, 0 ;
      /* buttons */
      --color-button-accent: 0, 117, 232;
      --color-button-accent-hover: 238, 242, 255;
      --color-button-muted: 99, 102, 241;
    }
  }


  /* colors: {
    edenv: "#B7CD00",
    edenc: "#36A9E1",
    edenb: "#173277",
    edeng: "#5F6C72",
},
backgroundColor: {
    "vert-bg": "#B7CD00",
    "ciel-bg": "#36A9E1",
    "bleu-bg": "#173277",
    "gris-bg": "#5F6C72", */